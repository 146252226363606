import { CommonModule } from "@angular/common";
import {
  Component,
  computed,
  DestroyRef,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
  WritableSignal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { PdfViewerComponent, PdfViewerModule } from "ng2-pdf-viewer";
import { switchMap, tap, throwError } from "rxjs";

import { ErrorHandlingService } from "@app/core/services";
import { IconButtonComponent } from "@app/shared";
import { ConnectionRequestDto } from "src/api/dso-portal/generated/models";
import { ConnectionRequestsService } from "src/api/dso-portal/generated/services";

import { DownloadRequestDirective } from "../../download-request.directive";

@Component({
  standalone: true,
  selector: "dso-pdf",
  imports: [
    PdfViewerModule,
    IconButtonComponent,
    TranslateModule,
    CommonModule,
    DownloadRequestDirective,
  ],
  templateUrl: "./pdf.component.html",
  styleUrl: "./pdf.component.scss",
})
export class PdfComponent implements OnInit, OnDestroy {
  /**
   * Should be adjusted with container query, it is needed because pdf lib
   * throws and error on display: none
   */
  private static readonly EXPECTED_INNER_WITH_TO_DISPLAY_PDF = 600;

  @ViewChild(PdfViewerComponent, { static: false })
  private pdfComponent!: PdfViewerComponent;

  readonly #connectionRequestService = inject(ConnectionRequestsService);
  readonly #destroyRef: DestroyRef = inject(DestroyRef);
  readonly #route = inject(ActivatedRoute);
  readonly #errorHandlingService = inject(ErrorHandlingService);
  public readonly pdfSrc: WritableSignal<null | string> = signal(null);

  #isInnerWidthSmallerAsThreshold =
    window.innerWidth > PdfComponent.EXPECTED_INNER_WITH_TO_DISPLAY_PDF;
  public isPDFDisplayed = signal(this.#isInnerWidthSmallerAsThreshold);

  public currentRequest: WritableSignal<ConnectionRequestDto | undefined> =
    signal(undefined);

  readonly pdfViewerHeight = signal(0);
  readonly calculatedPdfViewerHeight = computed(() =>
    this.isPDFDisplayed() ? `${this.pdfViewerHeight()}px` : "unset",
  );
  readonly isPdfViewerRendered = signal(false);

  public ngOnInit(): void {
    this.#route.parent?.data
      .pipe(
        tap(({ connectionRequest }) =>
          this.currentRequest?.set(connectionRequest),
        ),
        switchMap(({ connectionRequest }) => {
          const id = connectionRequest?.id;
          if (!id) {
            return throwError(() => new Error("ID not present"));
          }
          return this.#connectionRequestService.downloadConnectionRequest({
            id: id,
            type: "PDF",
          });
        }),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe({
        next: (connectionRequestDownload) => {
          this.pdfSrc.set(
            URL.createObjectURL(connectionRequestDownload as Blob),
          );
        },
        error: (error) => {
          this.#errorHandlingService.handleError(error, {
            shouldRedirect: false,
            showErrorSnackbar: true,
          });
        },
      });
  }

  public ngOnDestroy(): void {
    if (this.pdfSrc()) {
      URL.revokeObjectURL(this.pdfSrc()!);
    }
  }

  @HostListener("window:resize", ["$event"])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onResize(event: any): void {
    this.isPDFDisplayed.update(
      () =>
        event.target.innerWidth >
        PdfComponent.EXPECTED_INNER_WITH_TO_DISPLAY_PDF,
    );
  }

  public pageRendered(): void {
    const clientHeight =
      this.pdfComponent.pdfViewerContainer.nativeElement.children[1]
        .clientHeight;
    this.pdfViewerHeight.set(clientHeight);
    this.pdfComponent.pdfViewer.currentScaleValue = "page-width";
    this.isPdfViewerRendered.set(true);
  }
}
