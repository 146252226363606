import { Component, inject } from "@angular/core";
import {
  MatDialogContent,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { ButtonComponent } from "@eon-one/one-ui";
import { TranslateModule } from "@ngx-translate/core";

import { IconButtonComponent } from "@app/shared";

@Component({
  standalone: true,
  selector: "dso-delete-document-dialog",
  imports: [
    IconButtonComponent,
    MatDialogContent,
    MatDialogModule,
    TranslateModule,
    ButtonComponent,
  ],
  templateUrl: "./delete-document-dialog.component.html",
  styleUrl: "./delete-document-dialog.component.scss",
})
export class DeleteDocumentDialogComponent {
  readonly dialogRef = inject(MatDialogRef<DeleteDocumentDialogComponent>);
}
