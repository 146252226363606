import {
  Component,
  DestroyRef,
  effect,
  EventEmitter,
  inject,
  input,
  OnInit,
  Output,
  signal,
  WritableSignal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { catchError, EMPTY, finalize, tap } from "rxjs";

import {
  AdditionalDocumentService,
  DocumentUploadErrorMapperService,
  ErrorHandlingService,
} from "@app/core/services";
import { SnackBarConfigFactory } from "@app/core/utils";
import { FileSizePipe, IconButtonComponent } from "@app/shared";

import { PendingDocumentDetails } from "../documents-upload.component";

@Component({
  standalone: true,
  selector: "dso-pending-document",
  imports: [
    MatIconModule,
    MatTooltipModule,
    FileSizePipe,
    IconButtonComponent,
    TranslateModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: "./pending-document.component.html",
  styleUrl: "./pending-document.component.scss",
})
export class PendingDocumentComponent implements OnInit {
  currentLanguage = input.required<string>();
  connectionRequestId = input.required<string>();
  index = input.required<number>();
  documentDetails = input.required<PendingDocumentDetails>();
  documentDetailsWritable!: WritableSignal<PendingDocumentDetails>;

  @Output() unselectDocument = new EventEmitter<number>();

  readonly #snackBar = inject(MatSnackBar);
  readonly #errorHandlingService = inject(ErrorHandlingService);
  readonly #translateService = inject(TranslateService);
  readonly #additionalDocumentService = inject(AdditionalDocumentService);
  readonly #documentUploadErrorMapperService = inject(
    DocumentUploadErrorMapperService,
  );
  readonly #destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.documentDetailsWritable = signal(this.documentDetails());
  }

  constructor() {
    effect(
      () => {
        this.documentDetailsWritable.set(this.documentDetails());
      },
      { allowSignalWrites: true },
    );
  }

  public retryUpload(): void {
    // reset document state
    this.documentDetailsWritable.update((documentDetails) => ({
      ...documentDetails,
      uploadProgress: 0,
      uploadOngoing: true,
      errors: [],
    }));
    this.#additionalDocumentService
      .uploadDocument(
        this.connectionRequestId(),
        this.documentDetails().document,
      )
      .pipe(
        tap({
          next: (uploadProgress) =>
            this.documentDetailsWritable.update((documentDetails) => ({
              ...documentDetails,
              uploadProgress,
            })),
          complete: () =>
            this.#handleUploadSuccess(this.documentDetails().name),
        }),
        finalize(() =>
          this.documentDetailsWritable.update((documentDetails) => ({
            ...documentDetails,
            uploadOngoing: false,
          })),
        ),
        catchError(({ error }) => {
          this.documentDetailsWritable.update((documentDetails) => {
            return {
              ...documentDetails,
              retryAllowed:
                this.#documentUploadErrorMapperService.canRetryUpload(
                  error.code,
                ),
              errors: [
                this.#documentUploadErrorMapperService.getErrorMessage(
                  error.code,
                  error.errors[0].type,
                ),
              ],
            };
          });

          this.#errorHandlingService.handleError(error, {
            shouldRedirect: false,
            showErrorSnackbar: true,
            msgTranslationIdentifier: "SNACKBAR.UPLOAD_GENERIC_ERROR_MESSAGE",
            translationInterpolateParams: {
              documentName: this.documentDetails().name,
            },
          });
          return EMPTY;
        }),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe();
  }

  #handleUploadSuccess(documentName: string): void {
    this.unselectDocument.emit(this.index());

    this.#snackBar.open(
      this.#translateService.instant(
        "REQUESTS_DETAILS.DOCUMENT_UPLOAD_SUCCESS",
        { documentName },
      ),
      "X",
      SnackBarConfigFactory.build(["snack-bar-success"]),
    );
  }

  public removeDocumentSelection(): void {
    this.unselectDocument.emit(this.index());
  }
}
