import { CommonModule, DOCUMENT } from "@angular/common";
import {
  Component,
  DestroyRef,
  OnInit,
  Renderer2,
  WritableSignal,
  inject,
  signal,
  Signal,
} from "@angular/core";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import {
  MAT_DATE_FNS_FORMATS,
  provideDateFnsAdapter,
} from "@angular/material-date-fns-adapter";
import { NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { AuthService, GenericError } from "@auth0/auth0-angular";
import {
  FooterComponent,
  NavigationItem,
  SideNavigationComponent,
  SideNavigationElementComponent,
} from "@eon-one/one-ui";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { de, enGB } from "date-fns/locale";
import { filter, mergeMap } from "rxjs";
import { catchError, map } from "rxjs/operators";

import {
  AuthenticationService,
  EnvironmentService,
  ErrorHandlingService,
  LoadingService,
} from "./core/services";
import { APP_LANGUAGE, getLanguageLocale } from "./core/utils/";
import { NavigationComponent } from "./navigation/navigation.component";
import { SideNavigationLink, sideNavigationLinks } from "./navigation-links";
import { NavigationItemMapper } from "./shared";
@Component({
  standalone: true,
  selector: "dso-root",
  imports: [
    RouterOutlet,
    CommonModule,
    NavigationComponent,
    FooterComponent,
    SideNavigationComponent,
    SideNavigationElementComponent,
    TranslateModule,
    MatProgressBarModule,
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
    provideDateFnsAdapter(),
  ],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent implements OnInit {
  readonly #destroyRef = inject(DestroyRef);
  readonly #translateService = inject(TranslateService);
  readonly #renderer2 = inject(Renderer2);
  readonly #authenticationService = inject(AuthenticationService);
  readonly #authService = inject(AuthService);
  readonly #document = inject(DOCUMENT);
  readonly #router = inject(Router);
  readonly #gtmService = inject(GoogleTagManagerService);
  readonly #errorHandlingService = inject(ErrorHandlingService);
  readonly #environmentService = inject(EnvironmentService);

  public readonly isLoading: Signal<boolean> = inject(LoadingService).isLoading;

  public isAuthenticated$ = this.#authService.isAuthenticated$.pipe(
    catchError((error) =>
      this.#errorHandlingService.handleError(error, { shouldRedirect: true }),
    ),
  );

  public readonly currentLanguage: WritableSignal<string> = signal(
    APP_LANGUAGE.de.locale,
  );
  private readonly _adapter =
    inject<DateAdapter<unknown, unknown>>(DateAdapter);

  private readonly _locale = signal(inject<unknown>(MAT_DATE_LOCALE));

  public isSideNavigationExpanded = signal(false);
  public sideNavigationLinks: SideNavigationLink[] = sideNavigationLinks;
  public navigationItems: NavigationItem[] = sideNavigationLinks
    .filter((link) => link.isDisabled !== true)
    .map((link) => NavigationItemMapper.map(link));
  public readonly showNavigationElements: Signal<boolean | undefined>;
  public readonly additionalFooterLinks = ["de", "en"].map((language) => {
    return {
      url: this.#environmentService.releaseNotesUrl,
      translationKey: "COMMON.RELEASE_NOTES",
      language: language,
    };
  });

  constructor() {
    this.showNavigationElements = toSignal(
      this.#router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map((e) => (e as NavigationEnd).urlAfterRedirects !== "/error"),
      ),
      { initialValue: true },
    );
  }

  public ngOnInit(): void {
    this.#handleAuthErrors();
    this.#initLanguageBehavior();
    this.#initAnalyticsTracking();
  }

  #handleAuthErrors(): void {
    this.#authService.error$
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        filter(
          (e) =>
            e instanceof GenericError &&
            (e.error === "login_required" || e.error === "invalid_grant"),
        ),
        mergeMap(() => this.#authenticationService.logout()),
      )
      .subscribe();
  }

  #initLanguageBehavior(): void {
    this.#translateService.onLangChange
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((langChangeEvent) => {
        switch (langChangeEvent.lang) {
          case "de":
            this._adapter.setLocale(de);
            break;
          case "en":
            this._adapter.setLocale(enGB);
            break;
          default:
            throw new Error(`Unsupported language: ${langChangeEvent.lang}`);
        }

        this.currentLanguage.set(langChangeEvent.lang);
      });

    this.#setApplicationLanguageAndDateLocale();
    this.#initConsentBanner();
  }

  #setApplicationLanguageAndDateLocale(locale?: string): void {
    const selectedLanguage =
      locale ?? this.#translateService.getBrowserLang() ?? null;

    if (!selectedLanguage) {
      return;
    }

    const selectedLocaleCulture = getLanguageLocale(selectedLanguage);
    this.#translateService.use(selectedLocaleCulture);
  }

  #initConsentBanner(): void {
    const script = this.#renderer2.createElement("script");
    script.id = `usercentrics-cmp`;
    script.type = `application/javascript`;
    script.src = `https://app.usercentrics.eu/browser-ui/latest/loader.js`;
    script.setAttribute("data-settings-id", "Czq1d3T7ycTZ6f");
    script.setAttribute(
      "data-language",
      getLanguageLocale(this.#translateService.currentLang),
    );
    script.async = true;
    this.#renderer2.appendChild(this.#document.head, script);
  }

  #initAnalyticsTracking(): void {
    this.#router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          const gtmTag = {
            event: "page",
            pageName: event.url,
          };
          this.#gtmService.pushTag(gtmTag);
        }
      });
  }

  public onExpandedChange($event: boolean): void {
    this.isSideNavigationExpanded.set($event);
  }
}
